<template>
  <vs-avatar v-if="isAvatar && isBase64" :src="document" class="w-full h-full" />
  <vs-avatar v-else-if="isAvatar && !isBase64" :src="temporaryLinkUri" class="w-full h-full" />
  <div v-else>
      <img
        key="onlineImg"
        :src="temporaryLinkUri"
        alt="user-img"
        width="36"
        height="36"
        class="rounded-full shadow-md cursor-pointer block"
      />
  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "S3ImageGenerator",
  props: [
    "isBase64",
    "document",
    "isAvatar",
  ],
  data() {
    return {
      temporaryLinkUri: ""
    }
  },
  mounted() {
    this.generateTemporaryLink()
      .catch(error => {
        
      })
      .finally(() => {
        
      })
      .then(s3Data => {
        if (s3Data && s3Data.hasOwnProperty('status') && s3Data.status === 200) {
          this.temporaryLinkUri = s3Data.data.data.url;
        } else {
          
        }
      })
  },
  methods: {
    ...mapActions('s3ServiceModule', [
      'fetchFile'
    ]),

    async generateTemporaryLink() {
      try {
        if ( !this.isBase64 )
          return await this.fetchFile(this.document);
      }
      catch (e) {
        
      }
    }
  }
}
</script>

<style scoped>

</style>
