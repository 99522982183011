<template>
  <div class="relative">
    <div class="vx-navbar-wrapper navbar-full p-0">
      <vs-navbar class="navbar-custom navbar-skelton pt-2 px-4 pb-4 sm:pt-6 sm:px-8 sm:pb-10 h-20 sm:h-30" :class="navbarClasses" :style="navbarStyle" :color="navbarColor">
        <div class="flex">
          <div class="cursor-pointer flex justify-start items-center site-logo" v-if="Object.keys(merchant).length">
              <template v-if="checkLogoInArray">
                <img v-if="logoImage" :src="logoImage" alt="img" class="mr-2 responsive"/>
                <div class="logo-divider flex items-center" v-if="cobrandLogo">&nbsp;</div>
                <img v-if="cobrandLogo" :src="cobrandLogo" alt="img" class="ml-2 responsive"/>
              </template>
              <template v-else-if="companyLogo || cobrandLogo">
                <S3ImageGenerator :is-base64="false" :custom-class="'h-10 sm:h-14 max-w-full'" :document="companyLogo" :key="companyLogo && isAuthenticated"/>
                <div class="logo-divider flex items-center mx-4 sm:mx-6 min-h-full h-10 sm:h-14" v-if="cobrandLogo && isAuthenticated"></div>
                <S3ImageGenerator :is-base64="false" :custom-class="'h-10 sm:h-14 max-w-full'" :document="cobrandLogo" :key="cobrandLogo" v-if="cobrandLogo && isAuthenticated" />
              </template>
              <template v-else>
                <div class="site-logo" v-if="isAuthenticated">
                  <img :src="defaultLogo" :alt="appName" />
                </div>
              </template>
          </div>
          <div class="flex flex-col justify-center"></div>
        </div>
        <div class="the-navbar__user-meta flex items-center" v-if="isAuthenticated">
          <div class="con-img ds-wrapper">
            <vs-button type="flat" icon="logout" icon-size="small" @click="logOut">Log out</vs-button>
          </div>
        </div>
      </vs-navbar>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import S3ImageGenerator from "@/views/components/S3ImageGenerator";

export default {
  name: "the-navbar-horizontal",
  components: {
    S3ImageGenerator,
  },
  data() {
    return {
      appName: process.env.VUE_APP_NAME || "FlipPay",
      cobrandLogo: "",
      companyLogo: "",
      defaultLogo: "",
      flipLogo: require("@/assets/images/logo/flippay-logo.svg"),
      logoImage: "",
      paymentRequestDetail: {},
      prId: this.$route.params.prId,
      relloLogo: require("@/assets/images/logo/rellopay-logo.svg"),
      serverUrl: process.env.VUE_APP_API_URL,
    };
  },
  props: {
    logo: { type: String },
    navbarType: {
      type: String,
      required: true
    },
    merchant: { type: Object }
  },
  methods: {
    ...mapActions("paymentRequest", [
      "fetchPaymentRequestDetailByPRId",
    ]),
    logOut() {
      this.$router.push({ name: "Authenticate", params: { prId: this.prId } });
      this.showToastMessage("Logged out successfully", "Close the window to exit.", "success");
    },
    formattedUrl(url) {
      if (!url) return "";
      return url.includes("http") ? url : `https://${url}`;
    },
    async getPaymentRequestDetail() {
      let prId = this.prId;
      await this.fetchPaymentRequestDetailByPRId(prId).then((res) => {
        this.paymentRequestDetail = res.data.data;
        this.companyLogo = res.data.data.header.companyLogo ? res.data.data.header.companyLogo.logoImage : "";
        this.cobrandLogo = res.data.data.header.partnerLogo ? res.data.data.header.partnerLogo.logoImage : "";

        if (res.data.data.logos && res.data.data.logos.length) {
          this.logoImage = res.data.data.logos[0];

          if (res.data.data.logos.length > 1) {
            this.cobrandLogo = res.data.data.logos[1];
          }
        }

        if (!this.companyLogo && !this.cobrandLogo && !this.checkLogoInArray) {
          this.defaultLogo = this.appName.toLowerCase() == "flippay" ? this.flipLogo : this.relloLogo;
        }
      });
    },
  },
  mounted() {
    this.getPaymentRequestDetail();
  },
  computed: {
    checkLogoInArray() {
      return (this.paymentRequestDetail.logos && this.paymentRequestDetail.logos.length > 0);
    },

    accessToken() {
      return localStorage.getItem('accessToken');
    },
    isAuthenticated(){
      let routeName = this.$route.name;
      if (routeName === "Authenticate" || routeName === "VerifyCode" && !this.accessToken) {
        return false
      } else{
        return true
      }
    },
    navbarColor() {
      let color = "#fff";
      if (this.navbarType === "sticky") {
        color = "#f7f7f7";
      } else if (this.navbarType === "static") {
        if (this.scrollY < 50) {
          color = "#f7f7f7";
        }
      }

      this.isThemedark === "dark"
        ? color === "#fff"
          ? (color = "#10163a")
          : (color = "#262c49")
        : null;

      return color;
    },
    isThemedark() {
      return this.$store.state.theme;
    },
    navbarStyle() {
      return this.navbarType === "static"
        ? { transition: "all .25s ease-in-out" }
        : {};
    },
    navbarClasses() {
      return this.scrollY > 5 && this.navbarType === "static"
        ? null
        : "d-theme-dark-light-bg";
    },
    scrollY() {
      return this.$store.state.scrollY;
    },
    verticalNavMenuWidth() {
      return this.$store.state.verticalNavMenuWidth;
    },
    windowWidth() {
      return this.$store.state.windowWidth;
    }
  }
};
</script>