<template>
  <div class="the-navbar__user-meta flex items-center" v-if="activeUserInfo">
    <vs-dropdown class="cursor-pointer">
      <div class="con-img ml-3">
        <S3ImageGenerator
          v-if="typeof profileImage === 'string' && profileImage.length > 0"
          :key="profileImage"
          :document="profileImage"
          :is-base64="false"
          :is-avatar="false"
        />
        <div v-else style="width: 36px; height: 37px">
          <SudoImage :text="initials" :isNav="true" :isPreview="false" previewImage=""/>
        </div>
      </div>
      <vs-dropdown-menu class="vx-navbar-dropdown">
        <ul style="min-width: 9rem">
          <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white" @click="$router.push({ name: 'customer-profile' }).catch(() => {})">
            <span class="ml-2">Settings</span>
          </li>
          <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white" @click="logout">
            <span class="ml-2">Logout</span>
          </li>
        </ul>
      </vs-dropdown-menu>
    </vs-dropdown>
  </div>
</template>

<script>
import SudoImage from "../../../../views/customer/components/SudoImage";
import S3ImageGenerator from "../../../../views/customer/components/S3ImageGenerator";
import { clearLocalStorage } from "../../../../helper/localStorage.js";

export default {
  data() {
    return {
      test: "welcome",
    };
  },

  components: {
    SudoImage,
    S3ImageGenerator
  },

  computed: {
    activeUserInfo() {
      return localStorage.getItem("customer") ? JSON.parse(localStorage.getItem("customer")) : "";
    },

    profileImage() {
      const activeUser = this.$store.state.AppActiveUser;
      return activeUser.profileImage;
    },

    initials() {
      return this.activeUserInfo.firstName ? `${ this.activeUserInfo.firstName.charAt(0) } ${ this.activeUserInfo.lastName.charAt(0) }` : "";
    },
  },

  methods: {
    gotoSettings() {
      this.$router.push({
        name: "customer-profile",
        params: { merchantId: this.$route.params.merchantId },
      });
    },

    logout() {
      if (localStorage.getItem("accessToken")) {
        clearLocalStorage();
        this.$router
          .push({
            name: "customer-login",
            params: { id: this.$route.params.merchantId },
          })
          .catch(() => {});
      }
    },
  },
};
</script>
